<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Жолоочийн хүргэлтийн бүс</h3>
      <div class="action-section">
        <router-link to="/delivery-zones">
          <el-button type="success" class="mr10" size="mini">Бүх бүс</el-button>
        </router-link>
        <router-link to="/add-driver-zone">
          <el-button type="success" size="mini" icon="el-icon-plus"
            >Нэмэх</el-button
          >
        </router-link>
      </div>
    </div>
    <div class="panel">
      <el-table size="mini" :data="deliveryZones" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="prop">
            <el-table size="mini" :data="prop.row.zone_configs">
              <el-table-column
                prop="tplh_min"
                label="TPR MIN"
              ></el-table-column>
              <el-table-column
                prop="tplh_max"
                label="TPR MAX"
              ></el-table-column>
              <el-table-column
                prop="base_price"
                label="Хүргэлтийн төлбөр"
              ></el-table-column>
              <el-table-column
                prop="km_price"
                label="КМ нэмэлт үнэ"
              ></el-table-column>
              <el-table-column
                prop="delivery_time"
                label="Нэмэгдэх хугацаа"
              ></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="Нэр" prop="zone_name" width="120px">
        </el-table-column>
        <el-table-column label="Тайлбар" prop="description"> </el-table-column>
        <el-table-column width="300px">
          <template slot-scope="scope">
            <el-button
              type="info"
              size="mini"
              @click="getPolygon(scope.row._id)"
              >Бүс харах</el-button
            >
            <el-button
              type="success"
              size="mini"
              @click="getDeliveryZone(scope.row._id)"
              >Засах</el-button
            >
            <el-button type="danger" size="mini" @click="deleteZone(scope.row)"
              >Устгах</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageCounts"
        @current-change="setPage"
      >
      </el-pagination>
    </div>
    <el-dialog title="Бүс харах" :visible.sync="dialogFormVisible">
      <el-row>
        <el-col :span="14">
          <el-radio-group v-model="map_type">
            <el-radio label="polygon">Polygon</el-radio>
            <el-radio label="hexagon">Hexagon</el-radio>
          </el-radio-group>
          <div v-if="map_type === 'polygon'">
            <div class="partner-google-map">
              <gmap-map
                :center="center"
                :zoom="12"
                :options="mapOptions"
                :draggable="true"
                style="width:100%;  height: 100%; margin-top: 5px;"
              >
                <gmap-polygon
                  v-if="zonePolygon"
                  :paths="zonePolygon.polygon[0].points"
                  :options="{
                    fillColor: '#000000',
                    strokeColor: '#07bf10',
                    strokeOpacity: 0.9,
                    fillOpacity: 0.1
                  }"
                >
                </gmap-polygon>
              </gmap-map>
            </div>
          </div>
          <div v-else-if="map_type === 'hexagon'">
            <div class="panel">
              H3: {{ hexagonTable[zoomSize] }}
              <div class="partner-google-map">
                <gmap-map
                  ref="map"
                  :center="center"
                  :zoom="gmapDefaultZoom"
                  :options="mapOptions"
                  :draggable="true"
                  style="width:100%;  height: 100%; margin-top: 5px;"
                >
                  <gmap-polygon
                    v-for="(item, index) in getDefaultHexagonList()"
                    :key="index"
                    :paths="item.points"
                    :options="{
                      fillColor: hexagon_zone.allowed_list.includes(item.label)
                        ? '#000000'
                        : '#FFFFFF',
                      strokeOpacity: 0.2,
                      fillOpacity: getPolygonFillOpacity(
                        'allowed_list',
                        item.label
                      )
                        ? 0.5
                        : 0.1
                    }"
                    :editable="item.editable"
                  >
                  </gmap-polygon>
                </gmap-map>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <el-form v-if="zonePolygon" v-model="zonePolygon">
            <div class="panel">
              <el-select
                v-model="selectedLog"
                filterable
                placeholder="Өмнөх түүхүүд"
                class="mt10"
                @change="log(selectedLog)"
              >
                <el-option
                  v-for="(item, index) in deliveryZoneLogs"
                  :key="index"
                  :label="item.created_at"
                  :value="item._id"
                >
                </el-option>
              </el-select>
              <el-form-item label="Бүсийн нэр">
                <el-input disabled v-model="zonePolygon.zone_name"></el-input>
              </el-form-item>
              <el-form-item label="Тайлбар" prop="description">
                <el-input
                  :rows="5"
                  type="textarea"
                  v-model="zonePolygon.description"
                  disabled
                ></el-input>
              </el-form-item>
              <header>
                Тохиргоонууд
              </header>
              <el-table
                style="width: 100%"
                :data="zonePolygon.zone_configs"
                class="zoneRuleTable"
              >
                <el-table-column label="TPR MIN">
                  <template slot-scope="prop">
                    <el-input
                      v-if="prop.row.isEditable"
                      size="mini"
                      :min="0"
                      v-model="prop.row.tplh_min"
                    ></el-input>
                    <span v-else>{{ prop.row.tplh_min }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="TPR MAX">
                  <template slot-scope="prop">
                    <el-input
                      v-if="prop.row.isEditable"
                      size="mini"
                      :min="0"
                      v-model="prop.row.tplh_max"
                    ></el-input>
                    <span v-else>{{ prop.row.tplh_max }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Үнэ">
                  <template slot-scope="prop">
                    <el-input
                      v-if="prop.row.isEditable"
                      size="mini"
                      :min="0"
                      v-model="prop.row.base_price"
                    ></el-input>
                    <span v-else>{{ prop.row.base_price }}₮</span>
                  </template>
                </el-table-column>
                <el-table-column label="КМ үнэ">
                  <template slot-scope="prop">
                    <el-input
                      v-if="prop.row.isEditable"
                      size="mini"
                      :min="0"
                      v-model="prop.row.km_price"
                    ></el-input>
                    <span v-else>{{ prop.row.km_price }}₮</span>
                  </template>
                </el-table-column>
                <el-table-column label="Хугацаа">
                  <template slot-scope="prop">
                    <el-input
                      v-if="prop.row.isEditable"
                      size="mini"
                      :min="0"
                      v-model="prop.row.delivery_time"
                    ></el-input>
                    <span v-else>{{ prop.row.delivery_time }}мин</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      title="Та бүс устгахдаа итгэлтэй байна уу?"
      :visible.sync="deleteDialog"
      width="30%"
    >
      <span>Устгах шалтгаан бичнэ үү?</span>
      <el-input
        style="margin-top: 20px"
        type="textarea"
        autosize
        placeholder="Тайлбар"
        v-model="deleteDesc"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="deleteDialogClose">Үгүй</el-button>
        <el-button size="mini" type="success" @click="deleteDeliveryZone"
          >Тийм</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as axios from "axios";
import { getUserName } from "../utils/auth";
import { h3ToGeoBoundary, h3ToChildren } from "h3-js";
export default {
  name: "driverZone",
  created() {
    this.getDeliveryZones();
  },
  data() {
    return {
      deliveryZones: null,
      deliveryZone: null,
      pageCounts: 0,
      pageSize: 0,
      pageFrom: 0,
      dialogFormVisible: false,
      zonePolygon: null,
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      deliveryZoneLogs: [],
      selectedLog: null,
      otherPolygons: null,
      deleteDialog: false,
      deleteData: null,
      deleteDesc: "",
      map_type: "polygon",
      gmapDefaultZoom: 13,
      nearby_outlets: [],
      hexagon_zone: {
        allowed_list: [],
        blocked_list: []
      },
      hexagonResourceSize: 8,
      hexagonTable: {
        "20": 14,
        "19": 13,
        "18": 12,
        "17": 11,
        "16": 10,
        "15": 9,
        "14": 8,
        "13": 7,
        "12": 6,
        "11": 5,
        "10": 4,
        "9": 3,
        "8": 2,
        "7": 1
      },
      hexagonTableDataExplain: [
        {
          H3: "0",
          long: "1,107.712591000"
        },
        {
          H3: "1",
          long: "418.676005500"
        },
        {
          H3: "2",
          long: "158.244655800"
        },
        {
          H3: "3",
          long: "59.810857940"
        },
        {
          H3: "4",
          long: "22.606379400"
        },
        {
          H3: "5",
          long: "8.544408276"
        },
        {
          H3: "6",
          long: "3.229482772"
        },
        {
          H3: "7",
          long: "1.220629759"
        },
        {
          H3: "8",
          long: "0.461354684"
        },
        {
          H3: "9",
          long: "0.174375668"
        },
        {
          H3: "10",
          long: "0.065907807"
        },
        {
          H3: "11",
          long: "0.024910561"
        },
        {
          H3: "12",
          long: "0.009415526"
        },
        {
          H3: "13",
          long: "0.003559893"
        },
        {
          H3: "14",
          long: "0.001348575"
        },
        {
          H3: "15",
          long: "0.000509713"
        }
      ],
      zoomSize: null
    };
  },
  mounted() {
    this.getDefaultHexagonList();
    setTimeout(async () => {
      this.$refs.map.$on("zoom_changed", zoomSize => {
        this.zoomSize = zoomSize;
        this.hexagonResourceSize = this.hexagonTable[zoomSize];
      });
    }, 5000);
  },
  methods: {
    setPage(val) {
      this.getDeliveryZones(val * 10 - 10, this.pageSize);
    },
    async getDeliveryZones(from = "", size = "") {
      try {
        const axiosResponse = await axios.get(
          process.env.VUE_APP_DRIVER_API +
            `admin/delivery-zone/getDeliveryZones?from=${from}&size=${size}`
        );
        if (axiosResponse.data.status !== "success") {
          throw new Error(axiosResponse.data.error.message);
        }
        this.deliveryZones = axiosResponse.data.response.data;
        this.pageCounts = axiosResponse.data.response.total;
        this.pageSize = axiosResponse.data.response.size;
        this.pageFrom = axiosResponse.data.response.from;
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async getDeliveryZone(id) {
      this.$router.push({ path: "edit-driver-zone", query: { id: id } });
    },
    async getPolygon(id) {
      try {
        const payload = {};
        payload.id = id;
        const response = await axios.post(
          process.env.VUE_APP_DRIVER_API +
            "admin/delivery-zone/getDeliveryZoneById",
          payload
        );
        if (response.data.status !== "success") {
          throw new Error(response.data.error.message);
        }
        this.zonePolygon = response.data.response;
        if (this.zonePolygon.hasOwnProperty("polygon")) {
          this.zonePolygon.polygon.map(polygonItem => {
            let tempPolygon = [];
            polygonItem.coordinates[0].map(element => {
              tempPolygon.push({ lat: element[1], lng: element[0] });
            });
            polygonItem.points = tempPolygon;
          });
          this.dialogFormVisible = true;
        }
        this.logs(id);
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async logs(id) {
      try {
        const payload = {};
        this.hexagon_zone.allowed_list = [];
        payload.zone_id = id;
        const response = await axios.post(
          process.env.VUE_APP_DRIVER_API +
            "admin/delivery-zone/getDeliveryZoneLogs",
          payload
        );
        if (response.data.status !== "success") {
          throw new Error(response.data.error.message);
        }
        this.deliveryZoneLogs = response.data.response.data;
        if (
          !Object.prototype.hasOwnProperty.call(
            response.data.response.data[0].new,
            "hexagon_zone"
          )
        ) {
          this.hexagon_zone = this.hexagon_zone.allowed_list = [];
        } else {
          this.hexagon_zone = response.data.response.data[0].new.hexagon_zone;
        }
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async log(id) {
      try {
        const payload = {};
        payload.id = id;
        const response = await axios.post(
          process.env.VUE_APP_DRIVER_API +
            "admin/delivery-zone/getDeliveryZoneLog",
          payload
        );
        if (response.data.status !== "success") {
          throw new Error(response.data.error.message);
        }
        this.zonePolygon = response.data.response.old;
        if (this.zonePolygon.hasOwnProperty("polygon")) {
          this.zonePolygon.polygon.map(polygonItem => {
            let tempPolygon = [];
            polygonItem.coordinates[0].map(element => {
              tempPolygon.push({ lat: element[1], lng: element[0] });
            });
            polygonItem.points = tempPolygon;
          });
        }
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async deleteDeliveryZone() {
      getUserName().then(async name => {
        try {
          let payload = this.deleteData;
          payload.modified_by = name.attributes.email.split("@")[0];
          payload.is_deleted = true;
          payload.desc = this.deleteDesc;
          payload.id = this.deleteData._id;
          const response = await axios.post(
            process.env.VUE_APP_DRIVER_API +
              "admin/delivery-zone/updateDeliveryZone",
            payload
          );
          if (response.data.status === "success") {
            this.deleteDesc = "";
            this.deleteData = null;
            this.$notify({
              title: "Амжилттай",
              message: "Амжилттай Устгасан",
              type: "success"
            });
            this.deleteDialog = false;
            this.getDeliveryZones();
          } else {
            throw new Error(response.data.error.message);
          }
        } catch (err) {
          this.$notify.error({
            title: "Алдаа",
            message: err.message
          });
        }
      });
    },
    deleteZone(data) {
      this.deleteDialog = true;
      this.deleteData = data;
    },
    deleteDialogClose() {
      this.deleteDialog = false;
      this.deleteDesc = "";
      this.deleteData = null;
    },
    getDefaultHexagonList() {
      let tempHexagonList = [];
      let tempData = [];

      tempHexagonList.forEach(el => {
        let hexagons = h3ToChildren(el, this.hexagonResourceSize + 1);
        let tempHexagonList = [];
        hexagons = hexagons.concat(tempHexagonList);
        hexagons = [...new Set(hexagons)];
        const calculatedH3 = this.generatePolygonsFromHexagonList(hexagons);
        tempData = tempData.concat(calculatedH3);
      });
      const calculatedH3List = this.generatePolygonsFromHexagonList(
        this.hexagon_zone.allowed_list
      ).concat(this.generatePolygonsFromHexagonList(tempHexagonList));
      return calculatedH3List;
    },
    getPolygonFillColor(type, hex) {
      if (type === "hexogon") {
        return this.hexagon_zone.allowed_list.includes("hexogon", hex)
          ? "#000000"
          : "#FFFFFF";
      }
      return "#FFFFFF";
    },
    getPolygonFillOpacity(type) {
      if (type === "allowed_list") {
        return true;
      }
      return false;
    },
    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    }
  }
};
</script>
